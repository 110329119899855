import clsx from "clsx"
import type {FC, ReactNode} from "react"
import {MessageEmbedMediaFlags, type MessageEmbedType, MessageEmbedTypes} from "~/Constants"
import {
  EmbedAuthor,
  EmbedDescription,
  EmbedFields,
  EmbedFooter,
  EmbedProvider,
  EmbedTitle,
} from "~/components/channel/embeds/fields/EmbedFields"
import EmbedAudio from "~/components/channel/embeds/media/EmbedAudio"
import {EmbedGif, EmbedGifv} from "~/components/channel/embeds/media/EmbedGifv"
import {EmbedImage} from "~/components/channel/embeds/media/EmbedImage"
import EmbedVideo from "~/components/channel/embeds/media/EmbedVideo"
import {EmbedYouTube} from "~/components/channel/embeds/media/EmbedYouTube"
import type {EmbedMedia, MessageEmbed, MessageRecord} from "~/records/MessageRecord"
import markupStyles from "~/styles/Markup.module.css"
import * as ColorUtils from "~/utils/ColorUtils"
import {createCalculator} from "~/utils/DimensionUtils"

const MEDIA_MAX_WIDTH = 400
const MEDIA_MAX_HEIGHT = 300
const THUMBNAIL_SIZE = 80
const RICH_EMBED_MAX_WIDTH = 516
const BORDER_PADDING = 32

type EmbedWrapperProps = {
  children: ReactNode
  usesJustifiedAutoStyle: boolean
  maxWidth?: number
  color?: number
}

type MediaProps = {
  media?: Partial<EmbedMedia>
  maxWidth?: number
  maxHeight?: number
  isInline?: boolean
  isRichEmbed?: boolean
}

type EmbedProps = {
  embed: MessageEmbed
  message: MessageRecord
}

const isValidMedia = (media?: Partial<EmbedMedia>): media is Required<EmbedMedia> => {
  return !!(
    media &&
    typeof media.proxy_url === "string" &&
    typeof media.url === "string" &&
    typeof media.width === "number" &&
    typeof media.height === "number"
  )
}

const mediaCalculator = createCalculator({
  maxWidth: MEDIA_MAX_WIDTH,
  maxHeight: MEDIA_MAX_HEIGHT,
})

const EmbedWrapper: FC<EmbedWrapperProps> = ({children, usesJustifiedAutoStyle, maxWidth, color}) => (
  <article
    className={clsx(
      "relative box-border grid max-w-max rounded",
      "bg-background-primary transition-colors duration-200",
      usesJustifiedAutoStyle && "justify-self-auto",
      markupStyles.markup,
    )}
    style={{
      maxWidth,
      borderLeft: color ? `4px solid ${ColorUtils.int2rgb(color)}` : undefined,
    }}
  >
    {children}
  </article>
)

const shouldRenderAsInlineThumbnail = (media?: Partial<EmbedMedia>, embedType?: MessageEmbedType): boolean => {
  if (!isValidMedia(media) || embedType === MessageEmbedTypes.VIDEO) {
    return false
  }

  // For rich embeds (links/articles), use additional criteria
  const isRichEmbed = embedType === MessageEmbedTypes.LINK || embedType === MessageEmbedTypes.ARTICLE

  // Calculate aspect ratio
  const aspectRatio = media.width / media.height

  // For rich embeds, we want to use thumbnail rendering if:
  // 1. Image is portrait (aspect ratio < 1)
  // 2. Image is very tall compared to width (aspect ratio < 0.75)
  // 3. Original image is significantly larger than our display size
  if (isRichEmbed) {
    const isPortrait = aspectRatio < 1
    const isTall = aspectRatio < 0.75
    const isLarge = media.width > MEDIA_MAX_WIDTH || media.height > MEDIA_MAX_HEIGHT

    if ((isPortrait || isTall) && isLarge) {
      return true
    }
  }

  // For other cases, use existing size-based logic
  const {dimensions: normalDimensions} = mediaCalculator.calculate({
    width: media.width,
    height: media.height,
  })

  const thumbnailCalc = createCalculator({
    maxWidth: THUMBNAIL_SIZE,
    maxHeight: THUMBNAIL_SIZE,
  })

  const {dimensions: thumbnailDimensions} = thumbnailCalc.calculate({
    width: media.width,
    height: media.height,
  })

  // Don't use thumbnail for wide images
  if (normalDimensions.width >= 300) {
    return false
  }

  // Use thumbnail if it fits nicely in thumbnail dimensions
  return thumbnailDimensions.width === THUMBNAIL_SIZE || thumbnailDimensions.height === THUMBNAIL_SIZE
}

const MediaRenderer: FC<MediaProps> = ({
  media,
  maxWidth = MEDIA_MAX_WIDTH,
  maxHeight = MEDIA_MAX_HEIGHT,
  isInline = false,
  isRichEmbed = false,
}) => {
  if (!isValidMedia(media)) {
    return null
  }

  const {dimensions} = mediaCalculator.calculate(
    {width: media.width, height: media.height},
    {maxWidth, maxHeight, forceScale: true},
  )

  return (
    <div
      className={clsx(
        "w-full overflow-hidden rounded contain-paint",
        isRichEmbed && "mt-4",
        isInline && "mt-2 ml-4 flex-shrink-0 justify-self-end",
      )}
      style={{
        gridColumn: isInline ? "2 / 2" : "1 / 1",
        gridRow: isInline ? "1 / 8" : "auto",
      }}
    >
      <EmbedImage
        src={media.proxy_url}
        originalSrc={media.url}
        naturalWidth={media.width}
        naturalHeight={media.height}
        width={dimensions.width}
        height={dimensions.height}
        placeholder={media.placeholder}
        constrain={true}
        isInline={isInline}
      />
    </div>
  )
}

export const Embed: FC<EmbedProps> = ({embed, message}) => {
  const calculateMaxWidth = (primaryMedia?: Partial<EmbedMedia>): number | undefined => {
    if (!isValidMedia(primaryMedia)) {
      return undefined
    }

    const {dimensions} = mediaCalculator.calculate({
      width: primaryMedia.width,
      height: primaryMedia.height,
    })

    const isSquareArticle =
      (embed.type === MessageEmbedTypes.ARTICLE || embed.type === MessageEmbedTypes.LINK) &&
      primaryMedia.width === primaryMedia.height &&
      dimensions.width <= 300

    if (isSquareArticle) {
      return undefined
    }

    if (embed.type === MessageEmbedTypes.VIDEO || dimensions.width >= 300) {
      return dimensions.width + BORDER_PADDING
    }

    return undefined
  }

  const renderRichEmbed = () => {
    const hasVideo = isValidMedia(embed.video)
    const hasImage = isValidMedia(embed.image)
    const hasThumbnail = isValidMedia(embed.thumbnail)
    const isInlineThumbnail = !hasVideo && shouldRenderAsInlineThumbnail(embed.thumbnail, embed.type)

    return (
      <EmbedWrapper
        color={embed.color}
        maxWidth={calculateMaxWidth(embed.video || embed.image || embed.thumbnail)}
        usesJustifiedAutoStyle={usesJustifiedAutoStyle}
      >
        <div style={{maxWidth: RICH_EMBED_MAX_WIDTH}}>
          <div
            className={clsx(
              "grid overflow-hidden p-2 px-4 pb-4 pl-3",
              isInlineThumbnail ? "grid-cols-[auto_min-content]" : "grid-cols-[auto]",
            )}
          >
            <EmbedProvider provider={embed.provider} />
            <EmbedAuthor author={embed.author} />
            <EmbedTitle title={embed.title} url={embed.url} />
            <EmbedDescription description={embed.description} messageId={message.id} channelId={message.channelId} />
            <EmbedFields fields={embed.fields ?? []} />

            {renderMedia({hasVideo, hasImage, hasThumbnail, isInlineThumbnail})}

            <EmbedFooter footer={embed.footer} timestamp={embed.timestamp} />
          </div>
        </div>
      </EmbedWrapper>
    )
  }

  const renderMedia = ({
    hasVideo,
    hasImage,
    hasThumbnail,
    isInlineThumbnail,
  }: {
    hasVideo: boolean
    hasImage: boolean
    hasThumbnail: boolean
    isInlineThumbnail: boolean
  }) => {
    if (hasVideo && embed.video) {
      return <MediaRenderer media={embed.video} isRichEmbed={true} />
    }

    if (isInlineThumbnail && embed.thumbnail) {
      return (
        <MediaRenderer media={embed.thumbnail} maxHeight={THUMBNAIL_SIZE} maxWidth={THUMBNAIL_SIZE} isInline={true} />
      )
    }

    if (hasImage && embed.image) {
      return <MediaRenderer media={embed.image} isRichEmbed={true} />
    }

    if (hasThumbnail && embed.thumbnail) {
      return <MediaRenderer media={embed.thumbnail} isRichEmbed={true} />
    }

    return null
  }

  const renderYouTubeEmbed = () => (
    <EmbedWrapper color={embed.color} maxWidth={calculateMaxWidth(embed.video)} usesJustifiedAutoStyle={true}>
      <div className="max-w-[516px]">
        <div className="grid overflow-hidden p-2 px-4 pb-4 pl-3">
          <EmbedProvider provider={embed.provider} />
          <EmbedAuthor author={embed.author} />
          <EmbedTitle title={embed.title} url={embed.url} />
          <EmbedYouTube embed={embed} />
        </div>
      </div>
    </EmbedWrapper>
  )

  const renderImageEmbed = () => {
    if (!embed.thumbnail) return null

    if (embed.thumbnail.flags & MessageEmbedMediaFlags.ANIMATED) {
      return (
        <EmbedGif
          embedURL={embed.thumbnail.url}
          proxyURL={embed.thumbnail.proxy_url!}
          naturalWidth={embed.thumbnail.width!}
          naturalHeight={embed.thumbnail.height!}
          placeholder={embed.thumbnail.placeholder}
        />
      )
    }

    return <MediaRenderer media={embed.thumbnail} maxHeight={MEDIA_MAX_HEIGHT} maxWidth={MEDIA_MAX_WIDTH} />
  }

  const renderVideoEmbed = () => {
    if (!isValidMedia(embed.video)) return null

    const {dimensions} = mediaCalculator.calculate({
      width: embed.video.width,
      height: embed.video.height,
    })

    return (
      <EmbedVideo
        src={embed.video.proxy_url}
        width={dimensions.width}
        height={dimensions.height}
        placeholder={embed.video.placeholder}
      />
    )
  }

  const renderGifvEmbed = () => {
    if (!(isValidMedia(embed.video) && isValidMedia(embed.thumbnail) && embed.url)) return null

    return (
      <EmbedGifv
        embedURL={embed.url}
        videoProxyURL={embed.video.proxy_url}
        videoURL={embed.video.url}
        naturalWidth={embed.thumbnail.width}
        naturalHeight={embed.thumbnail.height}
        placeholder={embed.thumbnail.placeholder}
      />
    )
  }

  const usesJustifiedAutoStyle =
    embed.type === MessageEmbedTypes.IMAGE ||
    embed.type === MessageEmbedTypes.VIDEO ||
    embed.type === MessageEmbedTypes.GIFV ||
    ((embed.type === MessageEmbedTypes.RICH ||
      embed.type === MessageEmbedTypes.ARTICLE ||
      embed.type === MessageEmbedTypes.LINK) &&
      (isValidMedia(embed.video) || isValidMedia(embed.image)))

  switch (embed.type) {
    case MessageEmbedTypes.RICH:
    case MessageEmbedTypes.ARTICLE:
    case MessageEmbedTypes.LINK:
      return renderRichEmbed()

    case MessageEmbedTypes.VIDEO:
      if (embed.provider?.url && new URL(embed.provider.url).hostname === "www.youtube.com") {
        return renderYouTubeEmbed()
      }
      return renderVideoEmbed()

    case MessageEmbedTypes.IMAGE:
      if (isValidMedia(embed.thumbnail)) {
        return renderImageEmbed()
      }
      break

    case MessageEmbedTypes.AUDIO:
      if (embed.audio?.proxy_url) {
        return <EmbedAudio src={embed.audio.proxy_url} title={embed.title} />
      }
      break

    case MessageEmbedTypes.GIFV:
      if (isValidMedia(embed.video) && isValidMedia(embed.thumbnail) && embed.url) {
        return renderGifvEmbed()
      }
      break
  }

  return null
}
