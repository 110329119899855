import EventEmitter from "eventemitter3"
import {GatewayCloseCodes, type GatewayOpCode, GatewayOpCodes, type StatusType} from "~/Constants"
import {ExponentialBackoff} from "~/lib/ExponentialBackoff"
import {Logger} from "~/lib/Logger"

enum GatewayTimeouts {
  HeartbeatAck = 500,
  Connection = 10000,
  ResumeWindow = 90000,
  RateLimit = 30000,
  MinReconnect = 1000,
  MaxReconnect = 60000,
}

enum GatewayConnectionState {
  Disconnected = "DISCONNECTED",
  Connecting = "CONNECTING",
  Identifying = "IDENTIFYING",
  Resuming = "RESUMING",
  Connected = "CONNECTED",
}

type GatewayPayload = {
  op: GatewayOpCode
  d?: any
  s?: number
  t?: string
}

type GatewayClientOptions = {
  token: string
  apiVersion?: number
  properties?: GatewayClientProperties
  debug?: boolean
  autoReconnect?: boolean
  maxReconnectAttempts?: number
}

type GatewayClientProperties = {
  os?: string
  browser?: string
  device?: string
  locale?: string
  browser_version?: string
  os_version?: string
  [key: string]: any
}

type CloseEvent = {
  code: number
  reason: string
  wasClean: boolean
}

class JsonEncoder {
  private readonly logger = new Logger("JsonEncoder")

  encode(data: any): string {
    try {
      return JSON.stringify(data)
    } catch (error) {
      this.logger.error("Failed to stringify JSON:", error)
      throw error
    }
  }

  decode(data: string): any {
    try {
      return JSON.parse(data)
    } catch (error) {
      this.logger.error("Failed to parse JSON:", error)
      throw error
    }
  }

  getName(): string {
    return "json"
  }
}

export class GatewayClient extends EventEmitter {
  private readonly logger = new Logger("GatewayClient")
  private readonly encoder = new JsonEncoder()
  private readonly backoff: ExponentialBackoff

  private websocket: WebSocket | null = null
  private connectionStartTime = 0
  private expectedClose = false
  private autoReconnect: boolean

  private state = GatewayConnectionState.Disconnected
  private sessionId: string | null = null
  private sequence = 0

  private heartbeatAckTimeoutId: number | null = null
  private pendingHeartbeatAck = false
  private connectionTimeoutId: number | null = null
  private reconnectTimeoutId: number | null = null

  constructor(
    private readonly url: string,
    private readonly options: GatewayClientOptions,
  ) {
    super()
    this.autoReconnect = options.autoReconnect ?? true
    this.backoff = new ExponentialBackoff({
      minDelay: GatewayTimeouts.MinReconnect,
      maxDelay: GatewayTimeouts.MaxReconnect,
      maxAttempts: options.maxReconnectAttempts ?? 10,
    })
    this.bindEventHandlers()
  }

  connect(): void {
    if (this.websocket) {
      this.cleanup()
    }

    const gatewayUrl = this.buildGatewayUrl()
    this.websocket = new WebSocket(gatewayUrl)
    this.connectionStartTime = Date.now()
    this.expectedClose = false

    this.attachWebSocketListeners()
    this.updateState(GatewayConnectionState.Connecting)
    this.setupConnectionTimeout()

    this.emit("connecting")
  }

  disconnect(code = 1000, reason = "Client disconnecting"): void {
    this.expectedClose = true
    this.cleanup()

    if (this.websocket?.readyState === WebSocket.OPEN) {
      this.websocket.close(code, reason)
    }

    this.updateState(GatewayConnectionState.Disconnected)
    this.emit("disconnected", {code, reason, wasClean: true})
  }

  private bindEventHandlers(): void {
    this.handleOpen = this.handleOpen.bind(this)
    this.handleMessage = this.handleMessage.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleError = this.handleError.bind(this)
  }

  private handleOpen(): void {
    this.logger.info("WebSocket connected")
    this.clearConnectionTimeout()
    this.emit("connected")
  }

  private handleMessage(event: MessageEvent): void {
    try {
      const payload = this.encoder.decode(event.data)
      this.processGatewayPayload(payload)
    } catch (error) {
      this.logger.error("Failed to decode message:", error)
      this.disconnect(GatewayCloseCodes.DECODE_ERROR, "Failed to decode message")
    }
  }

  private handleClose(event: CloseEvent): void {
    this.logger.warn(`WebSocket closed: [${event.code}] ${event.reason}`)

    const wasClean = event.wasClean || this.expectedClose
    this.cleanup()

    this.emit("close", {
      code: event.code,
      reason: event.reason,
      wasClean,
    })

    if (this.expectedClose) {
      this.updateState(GatewayConnectionState.Disconnected)
      return
    }

    switch (event.code) {
      case GatewayCloseCodes.AUTHENTICATION_FAILED:
      case GatewayCloseCodes.INVALID_API_VERSION:
      case GatewayCloseCodes.ALREADY_AUTHENTICATED:
        this.updateState(GatewayConnectionState.Disconnected)
        this.emit("error", new Error(`Authentication failed: ${event.reason}`))
        break
      case GatewayCloseCodes.RATE_LIMITED:
        if (this.autoReconnect) {
          this.scheduleReconnection(GatewayTimeouts.RateLimit)
        }
        break
      default:
        if (this.autoReconnect) {
          this.scheduleReconnection()
        } else {
          this.updateState(GatewayConnectionState.Disconnected)
        }
    }
  }

  private handleError(event: Event): void {
    this.logger.error("WebSocket error:", event)
    this.emit("error", event)

    if (this.websocket) {
      this.websocket.close(GatewayCloseCodes.UNKNOWN_ERROR, "WebSocket error")
    }
  }

  private processGatewayPayload(payload: GatewayPayload): void {
    this.logger.debug("<~", payload.t || payload.op, payload.d || "")

    if (payload.op === GatewayOpCodes.DISPATCH && payload.s !== undefined) {
      this.sequence = payload.s
    }

    switch (payload.op) {
      case GatewayOpCodes.DISPATCH:
        this.handleDispatch(payload)
        break
      case GatewayOpCodes.HEARTBEAT_PROBE:
        this.handleHeartbeatProbe()
        break
      case GatewayOpCodes.HEARTBEAT_ACK:
        this.handleHeartbeatAck(payload.d)
        break
      case GatewayOpCodes.HELLO:
        this.handleHello()
        break
      case GatewayOpCodes.INVALID_SESSION:
        this.handleInvalidSession(payload.d as boolean)
        break
    }

    this.emit("message", payload)
  }
  private handleDispatch(payload: GatewayPayload): void {
    if (payload.t === "READY") {
      this.sessionId = payload.d.session_id
      this.updateState(GatewayConnectionState.Connected)
      this.backoff.reset()
      this.emit("ready", payload.d)
    }

    this.emit("dispatch", payload.t, payload.d)
  }

  private handleHeartbeatProbe(): void {
    if (!this.isConnected()) {
      return
    }

    if (this.pendingHeartbeatAck) {
      this.logger.warn("Received probe while waiting for ACK, reconnecting")
      this.reconnect()
      return
    }

    this.sendPayload({
      op: GatewayOpCodes.HEARTBEAT_ACK,
      d: this.sequence,
    })

    this.pendingHeartbeatAck = true
    this.setupHeartbeatAckTimeout()
  }

  private handleHeartbeatAck(sequenceNumber: number | undefined): void {
    this.clearHeartbeatAckTimeout()
    this.pendingHeartbeatAck = false

    if (sequenceNumber !== undefined) {
      this.sequence = sequenceNumber
    }

    this.emit("heartbeatAck", this.sequence)
  }

  private handleHello(): void {
    const canResume =
      this.sessionId && this.sequence > 0 && Date.now() - this.connectionStartTime <= GatewayTimeouts.ResumeWindow

    if (canResume) {
      this.resumeSession()
    } else {
      this.identifySession()
    }
  }

  private handleInvalidSession(resumable: boolean): void {
    this.logger.warn("Invalid session, resumable:", resumable)

    if (resumable && this.sessionId) {
      this.resumeSession()
    } else {
      this.sessionId = null
      this.sequence = 0
      this.identifySession()
    }
  }

  private setupHeartbeatAckTimeout(): void {
    this.clearHeartbeatAckTimeout()

    this.heartbeatAckTimeoutId = window.setTimeout(() => {
      if (this.pendingHeartbeatAck) {
        this.logger.warn("Server heartbeat ACK timeout, reconnecting...")
        this.reconnect()
      }
    }, GatewayTimeouts.HeartbeatAck)
  }

  private identifySession(): void {
    this.updateState(GatewayConnectionState.Identifying)

    this.sendPayload({
      op: GatewayOpCodes.IDENTIFY,
      d: {
        token: this.options.token,
        properties: this.options.properties,
        version: this.options.apiVersion ?? 1,
      },
    })
  }

  private resumeSession(): void {
    if (!this.sessionId || !this.options.token) {
      this.identifySession()
      return
    }

    this.updateState(GatewayConnectionState.Resuming)

    this.sendPayload({
      op: GatewayOpCodes.RESUME,
      d: {
        token: this.options.token,
        session_id: this.sessionId,
        seq: this.sequence,
      },
    })
  }

  private reconnect(): void {
    this.cleanup()
    this.scheduleReconnection()
  }

  private scheduleReconnection(delay?: number): void {
    if (this.reconnectTimeoutId) return

    const reconnectionDelay = delay ?? this.backoff.next()

    if (reconnectionDelay === -1) {
      this.logger.error("Max reconnection attempts reached")
      this.updateState(GatewayConnectionState.Disconnected)
      this.emit("error", new Error("Max reconnection attempts reached"))
      return
    }

    this.logger.info(`Reconnecting in ${reconnectionDelay}ms`)

    this.reconnectTimeoutId = window.setTimeout(() => {
      this.reconnectTimeoutId = null
      this.connect()
    }, reconnectionDelay)
  }

  private sendPayload(payload: GatewayPayload): void {
    if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN) {
      return
    }

    try {
      const encoded = this.encoder.encode(payload)
      this.websocket.send(encoded)
      this.logger.debug("~>", payload.t || payload.op, payload.d || "")
    } catch (error) {
      this.logger.error("Failed to send message:", error)
      this.reconnect()
    }
  }

  private buildGatewayUrl(): string {
    const gatewayUrl = new URL(this.url)
    gatewayUrl.searchParams.set("v", (this.options.apiVersion ?? 1).toString())
    gatewayUrl.searchParams.set("encoding", this.encoder.getName())
    return gatewayUrl.toString()
  }

  private setupConnectionTimeout(): void {
    this.clearConnectionTimeout()

    this.connectionTimeoutId = window.setTimeout(() => {
      this.logger.warn("Connection timeout")
      this.cleanup()
      if (this.autoReconnect) {
        this.scheduleReconnection()
      }
    }, GatewayTimeouts.Connection)
  }

  private clearConnectionTimeout(): void {
    if (this.connectionTimeoutId) {
      clearTimeout(this.connectionTimeoutId)
      this.connectionTimeoutId = null
    }
  }

  private clearHeartbeatAckTimeout(): void {
    if (this.heartbeatAckTimeoutId) {
      clearTimeout(this.heartbeatAckTimeoutId)
      this.heartbeatAckTimeoutId = null
    }
  }

  private cleanup(): void {
    this.clearHeartbeatAckTimeout()

    if (this.reconnectTimeoutId) {
      clearTimeout(this.reconnectTimeoutId)
      this.reconnectTimeoutId = null
    }

    this.pendingHeartbeatAck = false

    if (this.websocket) {
      this.websocket.removeEventListener("open", this.handleOpen)
      this.websocket.removeEventListener("message", this.handleMessage)
      this.websocket.removeEventListener("close", this.handleClose)
      this.websocket.removeEventListener("error", this.handleError)

      if (this.websocket.readyState === WebSocket.OPEN) {
        try {
          this.websocket.close(1000, "Client cleanup")
        } catch (error) {
          this.logger.error("Error closing WebSocket:", error)
        }
      }

      this.websocket = null
    }
  }

  private attachWebSocketListeners(): void {
    if (!this.websocket) return

    this.websocket.addEventListener("open", this.handleOpen)
    this.websocket.addEventListener("message", this.handleMessage)
    this.websocket.addEventListener("close", this.handleClose)
    this.websocket.addEventListener("error", this.handleError)
  }

  private updateState(newState: GatewayConnectionState): void {
    const previousState = this.state
    this.state = newState

    this.logger.info(`State changed: ${previousState} -> ${newState}`)
    this.emit("stateChange", newState, previousState)
  }

  handleNetworkStatusChange(online: boolean): void {
    this.logger.info(`Network status changed - Online: ${online}`)

    if (online && !this.isConnected()) {
      this.reconnect()
    } else if (!online && this.isConnected()) {
      this.disconnect(1000, "Network offline")
    }
  }

  reset(reconnect = true): void {
    this.cleanup()

    this.state = GatewayConnectionState.Disconnected
    this.sessionId = null
    this.sequence = 0
    this.backoff.reset()
    this.expectedClose = false

    this.logger.info("Gateway client reset to initial state")

    if (reconnect) {
      this.connect()
    }
  }

  updatePresence(status: StatusType): void {
    this.sendPayload({
      op: GatewayOpCodes.PRESENCE_UPDATE,
      d: {status},
    })
  }

  setToken(token: string): void {
    this.options.token = token
  }

  getState(): GatewayConnectionState {
    return this.state
  }

  getSessionId(): string | null {
    return this.sessionId
  }

  getSequence(): number {
    return this.sequence
  }

  isConnected(): boolean {
    return this.state === GatewayConnectionState.Connected && this.websocket?.readyState === WebSocket.OPEN
  }

  isConnecting(): boolean {
    return (
      this.state === GatewayConnectionState.Connecting ||
      this.state === GatewayConnectionState.Identifying ||
      this.state === GatewayConnectionState.Resuming
    )
  }

  getBackoffAttempts(): number {
    return this.backoff.getCurrentAttempts()
  }

  getRemainingReconnectAttempts(): number {
    return this.backoff.getRemainingAttempts()
  }
}
